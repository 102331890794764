import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "nateVick",
  "categories": ["rails", "docker"],
  "date": "2020-04-28",
  "path": "/blog/rails-system-test-docker",
  "summary": "Use Rails system tests in a Docker development environment with headless Chrome or with the Docker host's Chrome.",
  "title": "Rails System Tests In Docker",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@natron99"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`At Hint, we use Docker extensively. It is our development environment for all of our projects. On a recent greenfield project, we wanted to use Rails `}<a parentName="p" {...{
        "href": "https://weblog.rubyonrails.org/2017/4/27/Rails-5-1-final/"
      }}>{`System Tests`}</a>{` to validate the system from end to end. `}</p>
    <p>{`In order to comfortably use System Tests inside of Docker we had to ask ourselves a few questions: `}</p>
    <ol>
      <li parentName="ol">{`How do we use RSpec for System Tests?`}</li>
      <li parentName="ol">{`How do we run the tests in headless mode in a modern browser?`}</li>
      <li parentName="ol">{`Can we run the test in a non-headless browser for building and debugging efficiently?`}</li>
    </ol>
    <h2>{`Context`}</h2>
    <p>{`In the context of answering these questions, we are going to first need to profile the Rails project to which these answers apply. Our Rails app:`}</p>
    <ul>
      <li parentName="ul">{`Uses Docker and Docker Compose.`}</li>
      <li parentName="ul">{`Uses RSpec for general testing.`}</li>
      <li parentName="ul">{`Has an entrypoint or startup script.`}</li>
    </ul>
    <p><em parentName="p">{`If some or none of the above apply to your app and you would like to learn more about our approach to Docker, take a look at this post: `}<a parentName="em" {...{
          "href": "https://hint.io/blog/rails-development-with-docker"
        }}>{`Dockerizing a Rails Project`}</a>{`.`}</em></p>
    <h2>{`Prep Work`}</h2>
    <p>{`If your project was started before Rails 5.1, some codebase preparation is necessary. Beginning in Rails 5.1, the Rails core team integrated Capybara meaning Rails now properly handles all the painful parts of full system tests, e.g. database rollback. This means tools like `}<inlineCode parentName="p">{`database_cleaner`}</inlineCode>{` are no longer needed. If it is present, remove `}<inlineCode parentName="p">{`database_cleaner`}</inlineCode>{` from your Gemfile and remove any related config (typically found in `}<inlineCode parentName="p">{`spec/support/database_cleaner.rb`}</inlineCode>{` , `}<inlineCode parentName="p">{`spec/spec_helper.rb`}</inlineCode>{` , or `}<inlineCode parentName="p">{`spec/rails_helper.rb`}</inlineCode>{`).`}</p>
    <h2>{`Dependency Installation`}</h2>
    <p>{`After that codebase prep has been completed, verify that RSpec ≥ 3.7 and the required system tests helper gems are installed. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`group `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:development`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:test`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'rspec-rails'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'>= 3.7'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

group `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:test`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'capybara'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'>= 2.15'`}</span>{`
  gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'selenium-webdriver'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# gem 'chromedriver-helper' don't leave this cruft in your Gemfile.:D`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`These are the default system test helper gems installed with `}<inlineCode parentName="p">{`rails new`}</inlineCode>{` after Rails 5.1. We will not need `}<inlineCode parentName="p">{`chromedriver-helper`}</inlineCode>{` since we will be using a separate container for headless Chrome with `}<inlineCode parentName="p">{`chromedriver`}</inlineCode>{`. `}</p>
    <p><em parentName="p">{`Note: The configuration below has been tested on Mac and Linux, but not Windows.`}</em></p>
    <h2>{`Docker`}</h2>
    <p>{`Speaking of containers, let's add that service to the `}<inlineCode parentName="p">{`docker-compose.yml`}</inlineCode>{` configuration file.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# other services...`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`selenium`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` selenium/standalone`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`chrome`}</code></pre></div>
    <p>{`We have added the `}<inlineCode parentName="p">{`selenium`}</inlineCode>{` service, which pulls down the latest `}<inlineCode parentName="p">{`selenium/standalone-chrome`}</inlineCode>{` image. You may notice I have not mapped a port to the host. This service is for inter-container communication, so there is no reason to map a port. We will need to add an environment variable to the service (app in this case) that Rails/RSpec will be running on for setting a portion of the Selenium URL.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` .
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bundle exec rails server `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`p 3000 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`b '0.0.0.0'
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ... more config ...`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"3000:3000"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"43447:43447"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ... more config ...`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` SELENIUM_REMOTE_HOST=selenium`}</code></pre></div>
    <h2>{`Capybara`}</h2>
    <p>{`We added a port mapping for Capybara as well: `}<inlineCode parentName="p">{`43447:43447`}</inlineCode>{`. Now let's add the Capybara config at `}<inlineCode parentName="p">{`spec/support/capybara.rb`}</inlineCode>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# frozen_string_literal: true`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`RSpec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`configure `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`config`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
  headless `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ENV`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`fetch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'HEADLESS'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'false'`}</span>{`

  config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`before`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:each`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:system`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    driven_by `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:rack_test`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`before `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:each`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:system`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` js`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    url `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` headless
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token constant"
              }}>{`ENV`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'SELENIUM_REMOTE_HOST'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`:4444/wd/hub"`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'http://host.docker.internal:9515'`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

    driven_by `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:selenium`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` using`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:chrome`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      browser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`              `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:remote`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`                  url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      desired_capabilities`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:chrome`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Find Docker IP address`}</span>{`
    `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Capybara`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`server_host `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` headless
                             \``}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`sbin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`ip route`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`awk `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/scope/ { print $9 }'`}</span>{`\``}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`strip
                           `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
                             `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'0.0.0.0'`}</span>{`
                           `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Capybara`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`server_port `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'43447'`}</span>{`
    session_server       `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Capybara`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`current_session`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`server
    `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Capybara`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`app_host    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`session_server`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`host`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`:`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`session_server`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`port`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`after `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:each`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:system`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` js`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    page`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`driver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`browser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`manage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`logs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`get`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:browser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`each`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`log`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`when`}</span>{` `}<span parentName="code" {...{
            "className": "token regex"
          }}>{`/This page includes a password or credit card input in a non-secure context/`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Ignore this warning in tests`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`next`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
          message `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"[`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`log`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`level`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`] `}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`log`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`message`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`"`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`raise`}</span>{` message
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Let's break it down section by section.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`headless `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ENV`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`fetch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'HEADLESS'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'false'`}</span></code></pre></div>
    <p>{`We are using the `}<inlineCode parentName="p">{`headless`}</inlineCode>{` variable to make some decisions later in the file. This variable allows us to run `}<inlineCode parentName="p">{`bundle exec rspec`}</inlineCode>{` normally and run system tests against headless Chrome in the `}<inlineCode parentName="p">{`selenium`}</inlineCode>{` container. Or we run `}<inlineCode parentName="p">{`HEADLESS=false bundle exec rspec`}</inlineCode>{` and when a system test will attempt to connect to `}<inlineCode parentName="p">{`chromedriver`}</inlineCode>{` running on the host machine.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`before `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:each`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:system`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  driven_by `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:rack_test`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Our default driver for system tests will be `}<inlineCode parentName="p">{`rack_test`}</inlineCode>{`. It is the fastest driver available because it does not involve starting up a browser. It also means we cannot test JavaScript while using it, which brings us to the next section.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`before `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:each`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:system`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` js`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  url `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` headless
          `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span><span parentName="span" {...{
                "className": "token constant"
              }}>{`ENV`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token string"
              }}>{`'SELENIUM_REMOTE_HOST'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`:4444/wd/hub"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
          `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'http://host.docker.internal:9515'`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  driven_by `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:selenium`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` using`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:chrome`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    browser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`              `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:remote`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`                  url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    desired_capabilities`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:chrome`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
	
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ...more config...		`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Any specs with `}<inlineCode parentName="p">{`js: true`}</inlineCode>{` set will use this config. We set the `}<inlineCode parentName="p">{`url`}</inlineCode>{` for Selenium to use depending on if we are running headless or not. Notice the special Docker domain we are setting the non-headless url to; it is a URL that points to the host machine. The special domain is currently only available on Mac and Windows, so we will need to handle that for Linux later.`}</p>
    <p>{`We set our driver to `}<inlineCode parentName="p">{`:selenium`}</inlineCode>{` with config options for `}<inlineCode parentName="p">{`browser, url, desired_capabilities`}</inlineCode>{` .`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`before `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:each`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` type`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:system`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` js`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ...selenium config...`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Capybara`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`server_host `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` headless
                           \``}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`sbin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`ip route`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`awk `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/scope/ { print $9 }'`}</span>{`\``}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`strip
                         `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
                           `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'0.0.0.0'`}</span>{`
                         `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Capybara`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`server_port `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'43447'`}</span>{`
  session_server       `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Capybara`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`current_session`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`server
  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Capybara`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`app_host    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`session_server`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`host`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`:`}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`session_server`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`port`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Here we set the `}<inlineCode parentName="p">{`Capybara.server_host`}</inlineCode>{` address to the `}<inlineCode parentName="p">{`app`}</inlineCode>{` container IP address if headless or `}<inlineCode parentName="p">{`0.0.0.0`}</inlineCode>{` if not.`}</p>
    <p>{`The last part of RSpec configuration is to require this config in `}<inlineCode parentName="p">{`spec/rails_helper.rb`}</inlineCode>{` . `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# frozen_string_literal: true`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This file is copied to spec/ when you run 'rails generate rspec:install'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'spec_helper'`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ENV`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'RAILS_ENV'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'test'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`File`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`expand_path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'../../config/environment'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` __FILE__`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Prevent database truncation if the environment is production`}</span>{`
abort`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"The Rails environment is running in production mode!"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Rails`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`env`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`production`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'rspec/rails'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'support/capybara'`}</span></code></pre></div>
    <p>{`Next, we need to install `}<a parentName="p" {...{
        "href": "https://sites.google.com/a/chromium.org/chromedriver/downloads"
      }}>{`ChromeDriver`}</a>{` on the host machine. You will need to place it in a location in your `}<inlineCode parentName="p">{`$PATH`}</inlineCode>{`. Once it is there, when you want to run non-headless system tests, you will need to start ChromeDriver `}<inlineCode parentName="p">{`chromedriver --whitelisted-ips`}</inlineCode>{` in a new terminal session.  Now on a Mac, you should be able to run headless or non-headless system tests. Those commands again are:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#HEADLESS`}</span>{`
bundle `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` rspec

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#NON-HEADLESS`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`HEADLESS`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false bundle `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` rspec`}</code></pre></div>
    <h2>{`Special Linux Config`}</h2>
    <p>{`There is one last step for Linux users because of the special `}<inlineCode parentName="p">{`host.docker.internal`}</inlineCode>{` URL is not available. We need to add some config to the entrypoint or startup script to solve that issue.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`\${HOST_DOMAIN`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:=`}</span>{`"host.docker.internal"}`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function-name function"
          }}>{`check_host`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ping`}</span>{` -q -c1 `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$HOST_DOMAIN`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /dev/null `}<span parentName="code" {...{
            "className": "token operator"
          }}><span parentName="span" {...{
              "className": "token file-descriptor important"
            }}>{`2`}</span>{`>`}</span><span parentName="code" {...{
            "className": "token file-descriptor important"
          }}>{`&1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# check if the docker host is running on mac or windows`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{` check_host`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{`
  `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`HOST_IP`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`ip`}</span>{` route `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`awk`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'NR==1 {print `}<span parentName="span" {...{
                "className": "token variable"
              }}>{`$3`}</span>{`}'`}</span><span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$HOST_IP`}</span>{` `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$HOST_DOMAIN`}</span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` /etc/hosts
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fi`}</span></code></pre></div>
    <p>{`We set an environment variable to the special Docker URL. We then create a function to check if the host responds to that URL. If it responds, we move on assuming we are running on Mac or Windows. If it does not respond, we assign the container's IP to an environment variable, then append a record to `}<inlineCode parentName="p">{`/etc/hosts`}</inlineCode>{`. We are now all set to run system tests on Linux as well.`}</p>
    <h2>{`Bonus: CI Setup`}</h2>
    <p>{`Let's wrap this up with config to run system tests on Circle CI. We need to add `}<inlineCode parentName="p">{`SELENIUM_REMOTE_HOST`}</inlineCode>{` and the Selenium Docker image to `}<inlineCode parentName="p">{`.circleci/config.yml`}</inlineCode></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`jobs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`parallelism`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`docker`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` circleci/ruby`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`2.6.0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`node
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`SELENIUM_REMOTE_HOST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` localhost
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` selenium/standalone`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`chrome`}</code></pre></div>
    <p>{`Connect with me on Twitter(`}<a parentName="p" {...{
        "href": "https://twitter.com/natron99"
      }}>{`@natron99`}</a>{`) to continue the conversation about Rails and Docker!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      